import { useTranslate } from "src/i18n/useTranslate"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { TMDialogProps } from "src/ui/Dialog/MDialog"

export function NotEnoughPermissionsDialog({
  open,
  onClose,
}: {
  open: TMDialogProps["open"]
  onClose: TMDialogProps["onClose"]
}) {
  const { t, langKeys } = useTranslate()

  return (
    <ConfirmDialog
      title={t(langKeys.not_enough_permissions)}
      description={t(langKeys.not_enough_permissions_description)}
      confirmLabel={t(langKeys.close)}
      open={open}
      onClose={onClose}
      onConfirm={onClose}
    />
  )
}
