import { useMemo, useState } from "react"
import styled from "styled-components"

import { IProfileResponse } from "src/data/homeProfiles/types/homeProfilesTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { ComboboxStateful } from "src/ui/Combobox/ComboboxStateful"
import { Heading2Mixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export interface ISelectedOption {
  id: string
  name: string
}

export function SelectSettingsProfileStep({
  profiles,
  value,
  onChange,
}: {
  profiles: IProfileResponse[]
  value: ISelectedOption
  onChange: (option: ISelectedOption) => void
}) {
  const [search, setSearch] = useState("")
  const { t, langKeys } = useTranslate()

  const filteredProfiles = useMemo(() => {
    return profiles.filter((profile) =>
      profile.name.toLowerCase().includes(search.toLowerCase())
    )
  }, [profiles, search])

  function handleOnSelected(value: string) {
    const selectedProfile = profiles.find((profile) => profile.id === value)

    if (!selectedProfile) {
      throw new Error(
        "Selected profile when creating home is not defined in the profiles list"
      )
    }

    onChange(selectedProfile)
  }

  return (
    <>
      <Title>{t(langKeys.create_home_settings_profile_step_title)}</Title>

      <Description>
        {t(langKeys.create_home_settings_profile_step_body)}
      </Description>

      <ComboboxStateful
        label={t(langKeys.profile_title)}
        selectedValue={value.id}
        options={filteredProfiles.map((profile) => ({
          label: profile.name,
          value: profile.id,
        }))}
        onChange={handleOnSelected}
        onSearch={setSearch}
        required
        requiredIndicator
      />
    </>
  )
}

const Title = styled.div`
  ${Heading2Mixin};
  margin-bottom: ${spacing.M};
`

const Description = styled.div`
  margin-bottom: ${spacing.XL2};
`
