import { BilledNow } from "src/components/Homes/CreateHomeWizard/BillingSummary/BilledNow"
import { BillingLineItems } from "src/components/Homes/CreateHomeWizard/BillingSummary/BillingLineItems"
import { TFetchCreateHomeEstimateResponse } from "src/data/organizations/types/organizationTypes"
import { Divider } from "src/ui/Divider/Divider"
import { MCard } from "src/ui/MCard/MCard"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"

export function BillingSummary({
  createHomeEstimate,
}: {
  createHomeEstimate: TFetchCreateHomeEstimateResponse
}) {
  return (
    <MCard padding={spacing.M} border boxShadow={false}>
      <BillingLineItems
        lineItems={createHomeEstimate.line_items}
        currencyCode={createHomeEstimate.currency_code}
      />

      <Show if={createHomeEstimate?.amount > 0}>
        <Divider $margin={spacing.M} />
        <BilledNow
          formattedTotalAmount={createHomeEstimate.formatted_amount}
          vatExempt={createHomeEstimate.vat_exempt}
        />
      </Show>
    </MCard>
  )
}
