import { THomeAddress } from "src/data/homes/types/homeTypes"

export const EMPTY_AUTOCOMPLETE_ADDRESS: THomeAddress = {
  street_name1: "",
  post_code: "",
  country: "",
  city: "",
}

export const CREATE_HOME_FORM_ID = "home-form"
