import styled from "styled-components"

import { BillingSummary } from "src/components/Homes/CreateHomeWizard/BillingSummary/BillingSummary"
import { ConfirmationHomeDetails } from "src/components/Homes/CreateHomeWizard/ConfirmationHomeDetails"
import { ConfirmationMonitoringProfile } from "src/components/Homes/CreateHomeWizard/ConfirmationMonitoringProfile"
import { ISelectedOption } from "src/components/Homes/CreateHomeWizard/SelectSettingsProfileStep"
import { HREF_MINUT_SUBSCRIPTION_TERMS } from "src/constants/hrefs"
import { ILocation, THomeAddress } from "src/data/homes/types/homeTypes"
import { TFetchCreateHomeEstimateResponse } from "src/data/organizations/types/organizationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Divider } from "src/ui/Divider/Divider"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCard } from "src/ui/MCard/MCard"
import { MCheckbox } from "src/ui/MCheckbox/MCheckbox"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { StepId } from "./createHomeTypes"

export function CreateHomeConfirmStep({
  location,
  address,
  settingsProfile,
  handleOnEditClick,
  error,
  showSettingsProfileSection,
  createHomeEstimate,
  checkedTerms,
  onCheckTerms,
  checkedTermsError,
  hideBillingInformation,
}: {
  location: ILocation
  address: THomeAddress
  settingsProfile: ISelectedOption
  handleOnEditClick: (id: string) => void
  showSettingsProfileSection: boolean
  error?: boolean
  createHomeEstimate?: TFetchCreateHomeEstimateResponse
  checkedTerms: boolean
  onCheckTerms: () => void
  checkedTermsError: boolean
  hideBillingInformation?: boolean
}) {
  const { t, langKeys } = useTranslate()

  if (!hideBillingInformation && !createHomeEstimate) {
    throw Error("Create home estimate was undefined")
  }

  return (
    <>
      <MText variant="heading2" marginBottom={spacing.XL}>
        {t(langKeys.create_home_confirm_step_title)}
      </MText>

      <Box>
        <MCard border boxShadow={false}>
          <ConfirmationHomeDetails
            location={location}
            address={address}
            onEdit={() => handleOnEditClick(StepId.HomeAddressStep)}
          />
          {showSettingsProfileSection && (
            <>
              <Divider $margin={spacing.M} />
              <ConfirmationMonitoringProfile
                profileName={settingsProfile.name}
                onEditClick={() =>
                  handleOnEditClick(StepId.SelectSettingsProfileStep)
                }
              />
            </>
          )}
        </MCard>

        {/* Due to TS thinking the `createHomeEstimate` can be `undefined` after this conditional (even if we would catch that above) we have to double check here */}
        {!hideBillingInformation && createHomeEstimate && (
          <BillingSummary createHomeEstimate={createHomeEstimate} />
        )}
      </Box>

      {!hideBillingInformation && (
        <TermsWrapper>
          <MBanner type="info" fullWidth>
            <MCheckbox
              label={
                <MText variant="bodyS">
                  {t(langKeys.create_home_confirmation_accept_body)}{" "}
                  <ExternalLink href={HREF_MINUT_SUBSCRIPTION_TERMS}>
                    {t(langKeys.learn_more)}
                  </ExternalLink>
                </MText>
              }
              checked={checkedTerms}
              onCheck={onCheckTerms}
              error={checkedTermsError}
            />
          </MBanner>

          {checkedTermsError && (
            <MBanner type="error" fullWidth>
              {t(langKeys.create_home_confirmation_accept_error)}
            </MBanner>
          )}
        </TermsWrapper>
      )}

      {error && (
        <StyledMAlert type="error" fullWidth>
          {t(langKeys.failed_something_went_wrong)}
        </StyledMAlert>
      )}
    </>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const TermsWrapper = styled.div`
  display: grid;
  gap: ${spacing.XS};
  margin-top: ${spacing.L};
`

const StyledMAlert = styled(MBanner)`
  margin-top: ${spacing.M};
`
