import styled from "styled-components"

import { IconButton } from "@material-ui/core"

import { AdjustableHomeMap } from "src/components/Homes/HomeAddress/AdjustableHomeMap"
import { AdressWarning } from "src/components/Homes/HomeDetails/Overview/AddressCard"
import {
  formatPostalAddress,
  formatStreetname,
} from "src/components/Homes/HomeDetails/Overview/addressUtils"
import { ILocation, THomeAddress } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import PenIcon from "src/ui/icons/pen-outlined.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ConfirmationHomeDetails({
  location,
  address,
  onEdit,
}: {
  location?: ILocation
  address?: THomeAddress
  onEdit: () => void
}) {
  const { t, langKeys } = useTranslate()

  return (
    <MapCardGrid>
      <AdjustableHomeMap
        showTooltip={false}
        showAdjustButton={false}
        height="200px"
        markerWidth="30px"
        location={{
          latitude: location?.latitude || 0,
          longitude: location?.longitude || 0,
        }}
      />

      <div>
        <AdressWarning address={address} location={location} />

        {address && (
          <>
            <HomeDetailsWrapper>
              <MText variant="subtitle">
                {t(langKeys.create_home_confirmation_home_details_title)}
              </MText>

              <IconButton size="small" onClick={onEdit}>
                <PenIcon width={24} color={mColors.textPrimary} />
              </IconButton>
            </HomeDetailsWrapper>
            <MText variant="body" color="secondary">
              {formatStreetname(address)}
            </MText>
            <MText variant="body" color="secondary">
              {formatPostalAddress(address)}
            </MText>
          </>
        )}
      </div>
    </MapCardGrid>
  )
}

const HomeDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MapCardGrid = styled.div`
  display: grid;
  grid-gap: ${spacing.M};
`
