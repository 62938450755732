import styled from "styled-components"

import { getLocalizedPeriodCost } from "src/data/homes/logic/homeUtil"
import { TFetchCreateHomeEstimateResponse } from "src/data/organizations/types/organizationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatPrice } from "src/utils/formatPrice"

type TLineItem = TFetchCreateHomeEstimateResponse["line_items"][0]
type TLineItemCurrencyCode = TFetchCreateHomeEstimateResponse["currency_code"]

/**
 * Renders a summary of all costs involved in creating a home.
 * The create_home_estimate endpoint returns all information that is needed,
 * including e.g. if a pre-paid hometoken is used, call assist cost,
 * and in the future any other addons or line items that are added.
 */
export function BillingLineItems({
  lineItems,
  currencyCode,
}: {
  lineItems: TFetchCreateHomeEstimateResponse["line_items"]
  currencyCode: TLineItemCurrencyCode
}) {
  return (
    <Box>
      {lineItems.map((lineItem) => (
        <LineItemBox
          key={`${lineItem.name}-${lineItem.entity_type}-${lineItem.description}`}
        >
          <MText variant="subtitle">{lineItem.name}</MText>
          <MText variant="body" textAlign="right">
            <LineItemDescription
              lineItem={lineItem}
              currencyCode={currencyCode}
            />
            {lineItem.discount && (
              <LineItemDiscount discount={lineItem.discount} />
            )}
          </MText>
        </LineItemBox>
      ))}
    </Box>
  )
}

function LineItemDescription({
  lineItem,
  currencyCode,
}: {
  lineItem: TLineItem
  currencyCode: TLineItemCurrencyCode
}) {
  const { t, langKeys } = useTranslate()

  // If a pre-paid home token is consumed/activated, we render the
  // localized description, e.g. "Using 1 of 5 home tokens"
  if (lineItem.entity_type === "home_token") {
    return <div>{lineItem.description}</div>
  }

  // For line items with an annual billing period we show both monthly
  // and annual cost
  if (lineItem.billing_period === "year") {
    const monthlyCostString = getLocalizedPeriodCost({
      formattedAmount: lineItem.formatted_monthly_amount,
      billingPeriod: "month",
      t,
    })

    const annualCostString = getLocalizedPeriodCost({
      formattedAmount: lineItem.formatted_annual_amount,
      billingPeriod: "year",
      t,
    })

    return (
      <div>
        <div>{monthlyCostString}</div>
        <MText variant="bodyS" color="secondary">
          {`${t(langKeys.billed_as)} ${annualCostString}`}
        </MText>
      </div>
    )
  }

  // For line items with monthly billing periods we render the
  // monthly cost only
  if (lineItem.billing_period === "month") {
    return (
      <div>
        {getLocalizedPeriodCost({
          formattedAmount: lineItem.formatted_monthly_amount,
          billingPeriod: lineItem.billing_period,
          t,
        })}
      </div>
    )
  }

  // For all other cases we just render a formatted raw amount ourselves
  return <div>{formatPrice(lineItem.amount, currencyCode)}</div>
}

function LineItemDiscount({
  discount,
}: {
  discount: NonNullable<TLineItem["discount"]>
}) {
  return (
    <MText variant="subtitleS" color="good">
      {`${discount.description} -${discount.amount.formatted_amount}`}
    </MText>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const LineItemBox = styled.div`
  display: flex;
  justify-content: space-between;
`
