import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { orgsKeys } from "src/data/organizations/queries/organizationQueryCache"
import {
  TFetchCreateHomeEstimateResponse,
  TFetchCreateHomeEstimateResponseError,
} from "src/data/organizations/types/organizationTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchCreateHomeEstimate({
  quantity,
  orgId,
  options,
}: {
  quantity: number
  discountCode?: string
  orgId: string
  options?: UseQueryOptions<
    TFetchCreateHomeEstimateResponse,
    AxiosError<TFetchCreateHomeEstimateResponseError | undefined>,
    TFetchCreateHomeEstimateResponse,
    ReturnType<typeof orgsKeys.createHomeEstimate>
  >
}) {
  async function fetchCreateHomeEstimate() {
    const response =
      await minutApiHttpClient.post<TFetchCreateHomeEstimateResponse>(
        `${API_DEFAULT}/organizations/${orgId}/estimates/create_home_estimate`,
        {
          quantity,
        }
      )
    return response.data
  }

  return useQuery({
    queryKey: orgsKeys.createHomeEstimate(orgId),
    queryFn: fetchCreateHomeEstimate,
    ...options,
  })
}
